/* eslint-disable prettier/prettier */
import React /* , {memo,useState} */ from 'react'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { getUser } from '../services/auth'
import { cache } from '../cache'
import mixpanel from 'mixpanel-browser'
import * as Sentry from '@sentry/browser'
import {
  useErrorModalGraphql,
  ErrorModalGraphqlType,
} from '../components/errors/ErrorModalGraphql'

const httpLink = createHttpLink({
  uri: process.env.MIDDLE_END_URL,
})

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { ignore_dnt: true })

const getClearErrorObjectSentry = err => {
  const { operation, networkError, graphQLErrors, response } = err
  const errObj = {
    operation: operation ? JSON.parse(JSON.stringify(operation)) : operation,
    networkError: {
      message: networkError?.message,
      stack: networkError?.stack,
      response: networkError?.response,
      statusCode: networkError?.statusCode,
      bodyText: networkError?.bodyText,
    },
    graphQLErrors: graphQLErrors
      ? JSON.parse(JSON.stringify(graphQLErrors))
      : graphQLErrors,
    response: response ? JSON.parse(JSON.stringify(response)) : response,
  }
  return errObj
}

const onModalErrorNetwork = onError(error => {
  const { operation, networkError, graphQLErrors, response } = error
  const context = operation.getContext()
  Sentry.captureException(getClearErrorObjectSentry(error), context)
  if (networkError?.message !== 'Failed to fetch') {
    const [graphQLError] = graphQLErrors
    const {
      id,
      title,
      type,
      code,
      icon,
      header,
      showHelp,
      errorHandled,
    } = graphQLError.extensions
    if (context?.modalOky && graphQLErrors) {
      useErrorModalGraphql(graphQLError?.message, {
        type: type || ErrorModalGraphqlType.ERROR.code,
        id: id || ErrorModalGraphqlType.ERROR.code,
        title,
        code,
        mixpanelTr: context?.mixpanelTr,
        icon,
        header,
        showHelp,
        errorHandled,
      })
    } else if (
      context?.modalOky &&
      networkError?.message &&
      networkError?.message !== 'Load failed'
    ) {
      useErrorModalGraphql(networkError.message, {
        type: ErrorModalGraphqlType.ERROR.code,
        showHelp: ErrorModalGraphqlType.ERROR.showHelp,
        mixpanelTr: context?.mixpanelTr,
      })
    } else if (context?.modalOky && response?.errors) {
      const [errorResponse] = response?.errors
      useErrorModalGraphql(errorResponse.message, {
        type: ErrorModalGraphqlType.CONNECTION.code,
        showHelp: ErrorModalGraphqlType.CONNECTION.showHelp,
        mixpanelTr: context?.mixpanelTr,
      })
    } else if (
      !context?.modalOky &&
      graphQLError?.message ===
        'Por seguridad tu cuenta ha sido suspendida, contáctanos para ayudarte.'
    ) {
      useErrorModalGraphql(graphQLError?.message, {
        type: type || ErrorModalGraphqlType.ERROR.code,
        id: id || ErrorModalGraphqlType.ERROR.code,
        title,
        code,
        mixpanelTr: context?.mixpanelTr,
        icon,
        header,
        showHelp,
        errorHandled,
      })
    }
  } else {
    useErrorModalGraphql(ErrorModalGraphqlType.CONNECTION.description, {
      type: ErrorModalGraphqlType.CONNECTION.code,
      id: ErrorModalGraphqlType.CONNECTION.id,
      title: ErrorModalGraphqlType.CONNECTION.title,
      description: ErrorModalGraphqlType.CONNECTION.description,
      showHelp: ErrorModalGraphqlType.CONNECTION.showHelp,
    })
  }
})

const authLink = new ApolloLink((operation, forward) => {
  const hasToken = !!getUser().token
  operation.setContext({
    headers: {
      authorization: hasToken ? `Bearer ${getUser().token}` : '',
    },
    modalOky: operation.variables?.modalOky
      ? operation.variables.modalOky
      : false,
    mixpanelTr: operation.variables?.mixpanelTrack,
  })
  operation.variables.modalOky = undefined
  operation.variables.mixpanelTrack = undefined
  return forward(operation)
})

const client = new ApolloClient({
  cache,
  link: onModalErrorNetwork.concat(authLink.concat(httpLink)),
  name: 'oky-web',
})

function Apollo({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
export default Apollo
